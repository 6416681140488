// src/App.tsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, setPersistence, browserLocalPersistence, signOut } from 'firebase/auth';
import Login from './components/Login';
import SelectAvatar from './components/SelectAvatar';
import Home from './components/Home';
import JournalDashboard from './components/JournalDashboard';
import JournalEntry from './components/JournalEntry';
import MoodTracker from './components/MoodTracker';
import MoodHistory from './components/MoodHistory';
import Chat from './components/Chat';  // Import Chat component
import SOS from './components/SOS';
import Helplines from './components/Helplines';
import JournalDetail from './components/JournalDetail';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isGuest, setIsGuest] = useState<boolean>(localStorage.getItem('isGuest') === 'true');

  // Set persistence and check if the user is logged in
  useEffect(() => {
    // Set persistence to local so users stay logged in
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Check if the user is authenticated
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setIsAuthenticated(true);
            setIsGuest(false);  // Clear guest if authenticated
          } else {
            setIsAuthenticated(false);
            setIsGuest(localStorage.getItem('isGuest') === 'true'); // Check if guest session is active
          }
        });
      })
      .catch((error) => {
        console.error('Error setting persistence:', error);
        setIsAuthenticated(false);
      });
  }, []);


  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      setIsGuest(false);

      // Clear guest-specific data from localStorage
      localStorage.removeItem('isGuest');
      localStorage.removeItem('guestPetName');
      localStorage.removeItem('guestPetImage');
      localStorage.removeItem('guestStreak');
      localStorage.removeItem('guestPawPrints');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };



  if (isAuthenticated === null && !isGuest) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div>
        
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login setIsGuest={setIsGuest} />} />
          <Route path="/register" element={<Register setIsGuest={setIsGuest} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={isAuthenticated || isGuest ? <Navigate to="/home" /> : <Register setIsGuest={setIsGuest}/>} />

          {/* Protected Routes */}
          <Route path="/select-avatar" element={<SelectAvatar isGuest={isGuest} />} />
          <Route path="/home" element={<Home isGuest={isGuest} handleLogout={handleLogout} />} />
          <Route path="/journal" element={isAuthenticated || isGuest ? <JournalDashboard /> : <Navigate to="/" />} />
          <Route path="/new-journal" element={isAuthenticated || isGuest ? <JournalEntry /> : <Navigate to="/" />} />
          <Route path="/journal/:id" element={isAuthenticated || isGuest ? <JournalDetail /> : <Navigate to="/" />} />
          <Route path="/mood" element={isAuthenticated || isGuest ? <MoodTracker /> : <Navigate to="/" />} />
          <Route path="/mood-history" element={isAuthenticated || isGuest ? <MoodHistory /> : <Navigate to="/" />} />
          <Route path="/chat" element={isAuthenticated || isGuest ? <Chat /> : <Navigate to="/" />} />
          <Route path="/sos" element={isAuthenticated || isGuest ? <SOS /> : <Navigate to="/" />} />
          <Route path="/helplines" element={isAuthenticated || isGuest ? <Helplines /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
