// src/components/SOS.tsx
import { useNavigate } from 'react-router-dom';

const SOS = () => {
  const navigate = useNavigate();

  // Function to navigate to the Helpline page
  const handleViewHelplines = () => {
    navigate('/helplines');
  };

  // Function to navigate back to the Home page
  const handleGoHome = () => {
    navigate('/home');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white p-8">
      {/* Crisis Text */}
      <h1 className="text-4xl font-bold text-center mb-6">
        Are you in crisis? You are not alone. Help is just a call away.
      </h1>
      
      {/* Information Text */}
      <p className="text-lg text-center text-gray-300 mb-12">
        If you are dealing with abuse, trauma, or crisis, it is important that you talk 
        to a person you feel safe sharing with. If there is no one, call one of the helplines 
        below. Things will get better.
      </p>
      
      {/* View Helplines Button */}
      <button
        onClick={handleViewHelplines}
        className="bg-blue-500 text-white py-3 px-6 rounded-full text-lg font-bold hover:bg-blue-600 transition duration-300 mb-4"
      >
        View Helplines
      </button>

      {/* Go Home Button */}
      <button
        onClick={handleGoHome}
        className="bg-gray-700 text-white py-3 px-6 rounded-full text-lg font-bold hover:bg-gray-800 transition duration-300"
      >
        Go Home
      </button>
    </div>
  );
};

export default SOS;
