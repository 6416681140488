import { useState, useEffect } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

// Update paths if assets are in the public folder
const pets = [
  { id: 1, img: '/assets/pet1.jpeg' },
  { id: 2, img: '/assets/pet2.jpeg' },
  { id: 3, img: '/assets/pet3.jpeg' },
  { id: 4, img: '/assets/pet4.jpeg' },
  { id: 5, img: '/assets/pet5.jpeg' },
  { id: 6, img: '/assets/pet6.jpeg' },
  { id: 7, img: '/assets/pet7.jpeg' },
  { id: 8, img: '/assets/pet8.jpeg' },
  { id: 9, img: '/assets/pet9.png' },
];

interface SelectAvatarProps {
  isGuest: boolean;
}

const SelectAvatar = ({ isGuest }: SelectAvatarProps) => {
  const [selectedPet, setSelectedPet] = useState<any>(null);
  const [petName, setPetName] = useState('');
  const [error, setError] = useState('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const navigate = useNavigate();

  // Fetch current pet data if the user has already selected a pet
  useEffect(() => {
    const fetchPetData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setPetName(userData.petName || '');
          setSelectedPet(pets.find((pet) => pet.img === userData.petImagePath));
        }
      } else if (isGuest) {
        // Load pet data from localStorage for guest users
        const savedPetName = localStorage.getItem('guestPetName') || '';
        const savedPetImage = localStorage.getItem('guestPetImage') || '';
        setPetName(savedPetName);
        if (savedPetImage) {
          setSelectedPet(pets.find((pet) => pet.img === savedPetImage));
        }
      }
    };

    fetchPetData();
  }, [isGuest]);

  // Handle selecting the pet and showing confirmation box
  const handlePetSelect = (pet: any) => {
    setSelectedPet(pet);
    setShowConfirmBox(true);
  };

  // Save pet to Firestore or localStorage based on isGuest status
  const handleSavePet = async () => {
    if (!selectedPet || !petName) {
      setError('Please select a pet and enter a name.');
      return;
    }

    if (isGuest) {
      // Save to localStorage for guest users
      localStorage.setItem('guestPetName', petName);
      localStorage.setItem('guestPetImage', selectedPet.img);
      navigate('/home');
    } else {
      // Save to Firestore for authenticated users
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        try {
          await setDoc(userDocRef, {
            petName,
            petImagePath: selectedPet.img,
          }, { merge: true });
          navigate('/home');
        } catch (err) {
          setError('Failed to save pet. Please try again.');
        }
      }
    }
  };

  // Cancel and go back to Home
  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">Select Your Pet</h2>

      <div className="grid grid-cols-3 gap-4">
        {pets.map((pet) => (
          <button
            key={pet.id}
            className={`border-2 rounded-lg p-4 ${selectedPet?.id === pet.id ? 'border-blue-400' : 'border-gray-200'}`}
            onClick={() => handlePetSelect(pet)}
          >
            <img src={pet.img} className="h-24 w-24" alt={`Pet ${pet.id}`} />
          </button>
        ))}
      </div>

      <input
        type="text"
        placeholder="Enter your pet's name"
        className="w-full p-3 mt-6 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
        value={petName}
        onChange={(e) => {
          setPetName(e.target.value);
          setShowConfirmBox(true);
        }}
      />

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {showConfirmBox && (
        <div className="bg-white p-4 rounded-lg shadow-lg mt-6 w-96">
          <h3 className="text-lg font-bold mb-2">Are you sure?</h3>
          <p>You can always change your pet later from the profile menu.</p>

          <div className="flex justify-between mt-4">
            <button
              onClick={handleSavePet}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Yes, save pet
            </button>
            <button
              onClick={handleCancel}
              className="bg-gray-300 py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectAvatar;
